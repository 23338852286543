<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-card>
      <div>
        <b-badge
          class="mr-50"
          variant="light-success"
        >
          Total Startups: {{ startups.length }}
        </b-badge>
        <b-badge
          class="mr-50"
          variant="light-primary"
        >
          Pre-Incubatee : {{ startups.filter((startup) => startup.type.toLowerCase() === 'pre-incubatee').length }}
        </b-badge>

        <b-badge
          class="mr-50"
          variant="light-primary"
        >
          Incubatee : {{ startups.filter((startup) => startup.type.toLowerCase() === 'incubatee').length }}
        </b-badge>

        <b-badge
          class="mr-50"
          variant="light-primary"
        >
          Graduate : {{ startups.filter((startup) => startup.type.toLowerCase() === 'graduate').length }}
        </b-badge>

        <b-badge
          class="mr-50"
          variant="light-primary"
        >
          Network : {{ startups.filter((startup) => startup.type.toLowerCase() === 'network').length }}
        </b-badge>
      </div>

      <div class="d-flex filters-box mt-1">
        <div class="fl-col-1">
          <span>Filters</span>
        </div>
        <div class="fl-col-2 d-flex justify-content-start">
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[0].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[0].open=!filters[0].open"
                >
                  <span>{{ filters[0].title }}</span>
                  <div
                    v-if="filters[0].selected != null"
                    :class="`${filters[0].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[0].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[0].options"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[0].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[0].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[0].selected && filters[0].selected.indexOf(option) >= 0"
                      @change="selectFilter(0, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[1].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[1].open=!filters[1].open"
                >
                  <span>{{ filters[1].title }}</span>
                  <div
                    v-if="filters[1].selected != null"
                    :class="`${filters[1].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[1].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[1].options"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[1].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[1].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[1].selected && filters[1].selected === option"
                      @change="selectFilter(1, 'Single', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
                <div
                  v-if="filters[1].selected && filters[1].selected.value === 'custom'"
                  class="d-flex flex-column justify-content-between px-1 mt-50"
                >
                  <div class="">
                    <label>Minimum Value:</label>
                    <VueSlider
                      v-model="minValue"
                      :max="100"
                      :min="0"
                      :process-style="{ backgroundColor: '#7367f0' }"
                      :tooltip-style="{ backgroundColor: 'black', color: 'white' }"
                    />
                  </div>
                  <div class="slider-container">
                    <label>Maximum Value:</label>
                    <VueSlider
                      v-model="maxValue"
                      :max="100"
                      :min="0"
                      :process-style="{ backgroundColor: '#7367f0' }"
                      :tooltip-style="{ backgroundColor: 'black', color: 'white' }"
                    />
                  </div>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[2].open=!filters[2].open"
                >
                  <span>{{ filters[2].title }}</span>
                  <div
                    v-if="filters[2].selected != null"
                    :class="`${filters[2].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[2].selected.length ? filters[2].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[2].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[2].options"
                style="max-height:400px; overflow-y: scroll;"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[2].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[2].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[2].selected && filters[2].selected.indexOf(option) >= 0"
                      @change="selectFilter(2, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <b-navbar-nav class="nav align-items-start mr-50">
            <b-nav-item-dropdown
              class="dropdown-user nomarker"
              left
              toggle-class="d-flex align-items-center dropdown-user-link"
            >
              <template #button-content>
                <b-button
                  :variant="filters[3].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[3].open=!filters[3].open"
                >
                  <span>{{ filters[3].title }}</span>
                  <div
                    v-if="filters[3].selected != null"
                    :class="`${filters[3].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    {{ filters[3].selected.length ? filters[3].selected.length : '1' }}
                  </div>
                  <div
                    v-else
                    class="text-white py-25"
                    style="border-radius:50px; font-weight: bolder;"
                  >.</div>
                  <feather-icon
                    :icon="filters[3].open?'ChevronUpIcon':'ChevronDownIcon'"
                    class="ml-50"
                  />
                </b-button>
              </template>

              <div
                v-if="filters[3].options"
              >
                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[3].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="option in filters[3].options"
                  :key="option.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[3].selected && filters[3].selected.indexOf(option) >= 0"
                      @change="selectFilter(3, 'Multiple', option)"
                    >
                      {{ option.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
            </b-nav-item-dropdown>
          </b-navbar-nav>
          <!-- <div class="filter-holder">
          </div> -->
        </div>
        <div class="fl-col-3">
          <b-button
            variant="primary"
            size="sm"
            @click="openAdvanceModal()"
          >
            Advance
          </b-button>
        </div>
      </div>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between mt-2">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              class="d-inline-block mr-1"
              placeholder="Search"
              type="text"
            />
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="d-inline-flex mr-1"
              variant="outline-primary"
              :to="{name: 'add-startups'}"
            >
              <feather-icon
                class="mr-50"
                icon="PlusIcon"
              />
              <span class="align-middle text-nowrap">Invite Startup</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              class="d-inline-flex mr-1"
              variant="outline-primary"
              @click="$bvModal.show('uploadModal')"
            >
              <feather-icon
                class="mr-50"
                icon="UploadIcon"
              />
              <span class="align-middle text-nowrap">Upload Excel</span>
            </b-button>
          </div>
        </b-form-group>

      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength,
          position: 'top',
        }"
        :rows="startups"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: URL -->
          <span
            v-if="props.column.field === 'title'"
            class="text-nowrap"
          >
            {{ props.formattedRow[props.column.field] }}
            <b-link
              v-b-tooltip:hover="'View Details'"
              variant="flat-primary"
              size="sm"
              class="ml-50"
              @click="$showEntityModal(props.row.id)"
            ><feather-icon icon="InfoIcon" /></b-link>
          </span>
          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>

          <!-- Column: Button -->
          <span v-else-if="props.column.field === 'buttons'">
            <b-button
              class="ml-50"
              variant="outline-primary"
              size="sm"
              :to="{name: 'startups-manager', params: {id: props.row.id}}"
            >Manage</b-button>
            <b-button
              variant="outline-primary"
              class="ml-50"
              size="sm"
              :to="{name: 'startup-performance', params: {id: props.row.id}}"
            >Performance</b-button>
          </span>

          <!-- Column: Common -->
          <span
            v-else
            class="text-capitalize"
          >
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <!-- pagination -->
        <template
          slot="pagination-top"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mb-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                variant="light-primary"
              >{{
                props.total
              }} Startups
              </b-badge></span></span>

            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                align="right"
                class="mt-50"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <b-modal
      id="uploadModal"
      ref="modal"
      ok-title="Upload"
      title="Upload Startups CSV"
      no-close-on-backdrop
      @ok="addStartups"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Select Startups CSV"
            label-for="startupsfile"
          >
            <b-form-file
              id="startupsfile"
              class="d-inline-block mr-1"
              accept=".csv"
              drop-placeholder="Drop file here..."
              placeholder="Choose a file or drop it here..."
              @change="handleFileUpload($event)"
            />
          </b-form-group>
          <h6 v-if="content">
            The Uploaded CSV has ({{ content.length }}) Startup/s
          </h6>
          <Promised
            :promise="getLink()"
          >
            <template #default="data">
              <b-button
                :href="data"
                class="mr-50"
                size="sm"
                target="_blank"
                variant="flat-primary"
              >Download Sample CSV</b-button>
            </template>
          </Promised>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="filterModal"
      ok-only
      no-close-on-backdrop
      size="lg"
      title="Advance Filters"
    >
      <h5><strong>Filters</strong></h5>
      <b-row>
        <b-col
          md="4"
          style="border-right: solid 1px #ebe9f1"
          class="pr-0"
        >
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[0].selected === null ? 'py-75' : 'py-50'} ${filters[0].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(0)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[0].title }}</span>
              <div
                v-if="filters[0].selected != null"
                :class="`${filters[0].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[1].selected === null ? 'py-75' : 'py-50'} ${filters[1].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(1)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[1].title }}</span>
              <div
                v-if="filters[1].selected != null"
                :class="`${filters[1].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[1].selected.length ? filters[1].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[2].selected === null ? 'py-75' : 'py-50'} ${filters[2].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(2)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[2].title }}</span>
              <div
                v-if="filters[2].selected != null"
                :class="`${filters[2].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[2].selected.length ? filters[2].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[3].selected === null ? 'py-75' : 'py-50'} ${filters[3].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(3)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[3].title }}</span>
              <div
                v-if="filters[3].selected != null"
                :class="`${filters[3].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[3].selected.length ? filters[3].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[4].selected === null ? 'py-75' : 'py-50'} ${filters[4].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(4)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[4].title }}</span>
              <div
                v-if="filters[4].selected != null"
                :class="`${filters[4].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[4].selected.length ? filters[4].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[5].selected === null ? 'py-75' : 'py-50'} ${filters[5].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(5)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[5].title }}</span>
              <div
                v-if="filters[5].selected != null"
                :class="`${filters[5].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[5].selected.length ? filters[5].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
          <div
            :class="`h6 d-flex align-items-center mb-0 ${filters[6].selected === null ? 'py-75' : 'py-50'} ${filters[6].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(6)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[6].title }}</span>
              <div
                v-if="filters[6].selected != null"
                :class="`${filters[6].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[6].selected.length ? filters[6].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </div>
        </b-col>
        <b-col md="8">
          <div
            v-if="filters[0].options.length > 0"
            :style="`height: ${filters[0].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[0].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[0].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[0].selected && filters[0].selected.indexOf(option) >= 0"
                  @change="selectFilter(0, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[1].options.length > 0"
            :style="`height: ${filters[1].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[1].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[1].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[1].selected && filters[1].selected === option"
                  @change="selectFilter(1, 'Single', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[2].options.length > 0"
            :style="`height: ${filters[2].open ? 'auto' : '1px' }; overflow: hidden; max-height:400px; overflow-y: scroll;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[2].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[2].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[2].selected && filters[2].selected.indexOf(option) >= 0"
                  @change="selectFilter(2, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[3].options.length > 0"
            :style="`height: ${filters[3].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[3].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[3].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[3].selected && filters[3].selected.indexOf(option) >= 0"
                  @change="selectFilter(3, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[4].options.length > 0"
            :style="`height: ${filters[4].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[4].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[4].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[4].selected && filters[4].selected.indexOf(option) >= 0"
                  @change="selectFilter(4, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[5].options.length > 0"
            :style="`height: ${filters[5].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[5].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[5].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[5].selected && filters[5].selected.indexOf(option) >= 0"
                  @change="selectFilter(5, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filters[6].options.length > 0"
            :style="`height: ${filters[6].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[6].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="option in filters[6].options"
              :key="option.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[6].selected && filters[6].selected.indexOf(option) >= 0"
                  @change="selectFilter(6, 'Multiple', option)"
                >
                  {{ option.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BButton,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BNavbarNav,
  BNavItemDropdown,
  BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import gql from 'graphql-tag'
import Papa from 'papaparse'
import { Promised } from 'vue-promised'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { mapGetters } from 'vuex'

export default {
  components: {
    // BLink,
    VueGoodTable,
    VueSlider,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormSelect,
    BButton,
    Promised,
    BNavItemDropdown,
    BNavbarNav,
    BFormCheckbox,
  },
  data() {
    return {
      pageLength: 5,
      minValue: 0,
      maxValue: 100,
      open: false,
      isFiltered: false,
      filters: [
        {
          open: false,
          selected: null,
          title: 'Type',
          options: [
            {
              id: 0,
              title: 'Pre-Incubatee',
              value: 'pre-incubatee',
            },
            {
              id: 1,
              title: 'Incubatee',
              value: 'incubatee',
            },
            {
              id: 2,
              title: 'Graduate',
              value: 'graduate',
            },
            {
              id: 3,
              title: 'Network',
              value: 'startup',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Company Age',
          options: [
            {
              id: 0,
              title: '<6 months',
              value: '6',
            },
            {
              id: 1,
              title: '<=12 months',
              value: '12',
            },
            {
              id: 2,
              title: '<=18 months',
              value: '18',
            },
            {
              id: 3,
              title: '<=24 months',
              value: '24',
            },
            {
              id: 4,
              title: '<=36 months',
              value: '36',
            },
            {
              id: 5,
              title: '>36 months',
              value: '40',
            },
            {
              id: 6,
              title: 'Custom Range',
              value: 'custom',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Industry',
          options: [
            {
              id: 0,
              title: 'Agriculture and Allied Industries',
              value: 'Agriculture and Allied Industries',
            },
            {
              id: 1,
              title: 'Automobiles',
              value: 'Automobiles',
            },
            {
              id: 2,
              title: 'Auto Components',
              value: 'Auto Components',
            },
            {
              id: 3,
              title: 'Aviation',
              value: 'Aviation',
            },
            {
              id: 4,
              title: 'Banking',
              value: 'Banking',
            },
            {
              id: 5,
              title: 'Biotechnology',
              value: 'Biotechnology',
            },
            {
              id: 6,
              title: 'Cement',
              value: 'Cement',
            },
            {
              id: 7,
              title: 'Chemicals',
              value: 'Chemicals',
            },
            {
              id: 8,
              title: 'Consumer Durables',
              value: 'Consumer Durables',
            },
            {
              id: 9,
              title: 'Defence Manufacturing',
              value: 'Defence Manufacturing',
            },
            {
              id: 10,
              title: 'E-Commerce',
              value: 'E-Commerce',
            },
            {
              id: 11,
              title: 'Education and Training',
              value: 'Education and Training',
            },
            {
              id: 12,
              title: 'Electronics System Design and Manufacturing',
              value: 'Electronics System Design and Manufacturing',
            },
            {
              id: 13,
              title: 'Engineering and Capital Goods',
              value: 'Engineering and Capital Goods',
            },
            {
              id: 14,
              title: 'Financial Services',
              value: 'Financial Services',
            },
            {
              id: 15,
              title: 'FMCG',
              value: 'FMCG',
            },
            {
              id: 16,
              title: 'Gems and Jewellery',
              value: 'Gems and Jewellery',
            },
            {
              id: 17,
              title: 'Healthcare',
              value: 'Healthcare',
            },
            {
              id: 18,
              title: 'Infrastructure',
              value: 'Infrastructure',
            },
            {
              id: 19,
              title: 'Insurance',
              value: 'Insurance',
            },
            {
              id: 20,
              title: 'IT and BPM',
              value: 'IT and BPM',
            },
            {
              id: 21,
              title: 'Manufacturing',
              value: 'Manufacturing',
            },
            {
              id: 22,
              title: 'Media and Entertainment',
              value: 'Media and Entertainment',
            },
            {
              id: 23,
              title: 'Medical Devices',
              value: 'Medical Devices',
            },
            {
              id: 24,
              title: 'Metals and Mining',
              value: 'Metals and Mining',
            },
            {
              id: 25,
              title: 'MSME',
              value: 'MSME',
            },
            {
              id: 26,
              title: 'Oil and Gas',
              value: 'Oil and Gas',
            },
            {
              id: 27,
              title: 'Pharmaceuticals',
              value: 'Pharmaceuticals',
            },
            {
              id: 28,
              title: 'Ports',
              value: 'Ports',
            },
            {
              id: 29,
              title: 'Power',
              value: 'Power',
            },
            {
              id: 30,
              title: 'Railways',
              value: 'Railways',
            },
            {
              id: 31,
              title: 'Real Estate',
              value: 'Real Estate',
            },
            {
              id: 32,
              title: 'Renewable Energy',
              value: 'Renewable Energy',
            },
            {
              id: 33,
              title: 'Retail',
              value: 'Retail',
            },
            {
              id: 34,
              title: 'Roads',
              value: 'Roads',
            },
            {
              id: 35,
              title: 'Science and Technology',
              value: 'Science and Technology',
            },
            {
              id: 36,
              title: 'Services',
              value: 'Services',
            },
            {
              id: 37,
              title: 'Steel',
              value: 'Steel',
            },
            {
              id: 38,
              title: 'Telecommunications',
              value: 'Telecommunications',
            },
            {
              id: 39,
              title: 'Textiles',
              value: 'Textiles',
            },
            {
              id: 40,
              title: 'Tourism and Hospitality',
              value: 'Tourism and Hospitality',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Life-Cycle',
          options: [
            {
              id: 0,
              title: 'Ideation',
              value: 'Ideation',
            },
            {
              id: 0,
              title: 'Business Modelling',
              value: 'Business Modelling',
            },
            {
              id: 0,
              title: 'Prototyping',
              value: 'Prototyping',
            },
            {
              id: 0,
              title: 'MVP',
              value: 'MVP',
            },
            {
              id: 0,
              title: 'Market Traction',
              value: 'Market Traction',
            },
            {
              id: 0,
              title: 'Product Introduction',
              value: 'Product Introduction',
            },
            {
              id: 0,
              title: 'Revenue Generation',
              value: 'Revenue Generation',
            },
            {
              id: 0,
              title: 'Scaling',
              value: 'Scaling',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Offerings Type',
          options: [
            {
              id: 0,
              title: 'Product',
              value: 'Product',
            },
            {
              id: 1,
              title: 'Service',
              value: 'Service',
            },
            {
              id: 2,
              title: 'Hybrid',
              value: 'Hybrid',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Focus Area',
          options: [
            {
              id: 0,
              title: 'Profit',
              value: 'Profit',
            },
            {
              id: 1,
              title: 'Social Impact',
              value: 'Social Impact',
            },
            {
              id: 2,
              title: 'Environmental Impact',
              value: 'Environmental Impact',
            },
          ],
        },
        {
          open: false,
          selected: null,
          title: 'Customer Type',
          options: [
            {
              id: 0,
              title: 'B2C',
              value: 'B2C',
            },
            {
              id: 1,
              title: 'B2B',
              value: 'B2B',
            },
            {
              id: 2,
              title: 'B2G',
              value: 'B2G',
            },
            {
              id: 3,
              title: 'D2C',
              value: 'D2C',
            },
            {
              id: 4,
              title: 'B2B2C',
              value: 'B2B2C',
            },
            {
              id: 5,
              title: 'B2B2B',
              value: 'B2B2B',
            },
            {
              id: 6,
              title: 'B2B2G',
              value: 'B2B2G',
            },
            {
              id: 7,
              title: 'B2G2C',
              value: 'B2G2C',
            },
            {
              id: 8,
              title: 'B2G2B',
              value: 'B2G2B',
            },
            {
              id: 9,
              title: 'B2C2C',
              value: 'B2C2C',
            },
            {
              id: 10,
              title: 'B2C2B',
              value: 'B2C2B',
            },
            {
              id: 11,
              title: 'B2C2G',
              value: 'B2C2G',
            },
          ],
        },
      ],
      dir: false,
      filter: null,
      content: null,
      columns: [
        {
          label: 'Startup Name',
          field: 'title',
        },
        {
          label: 'Type',
          field: 'type',
          filterOptions: {
            enabled: true,
            filterDropdownItems: ['Pre-Incubatee', 'Incubatee', 'Graduate', 'Network'],
          },
        },
        {
          label: '',
          field: 'buttons',
          sortable: false,
        },
      ],
      startups: [],
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },

  computed: {
    ...mapGetters({
      selectedOrg: 'user/getSelectedOrgDetails',
    }),
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  methods: {

    getLink() {
      return fetch('https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=3474e06536034062b3d7995a19d55647.csv', {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
    handleFileUpload(event) {
      this.parseFile(event.target.files[0])
    },
    advanceSelector(index) {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[index].open = true
    },
    openAdvanceModal() {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[0].open = true
      this.$bvModal.show('filterModal')
    },
    selectFilter(index, mode, value) {
      if (mode === 'Single') {
        if (this.filters[index].selected === value) {
          this.filters[index].selected = null
        } else {
          this.filters[index].selected = value
        }
      } else if (mode === 'Multiple') {
        if (this.filters[index].selected !== null && this.filters[index].selected.indexOf(value) >= 0) {
          const temp = this.filters[index].selected
          temp.splice(temp.indexOf(value), 1)
          this.filters[index].selected = temp
          if (temp.length === 0) {
            this.filters[index].selected = null
          }
        } else {
          const temp = this.filters[index].selected ? this.filters[index].selected : []
          temp.push(value)
          this.filters[index].selected = temp
        }
      }
    },
    getList(index) {
      let string = ''
      if (this.filters[index].selected) {
        this.filters[index].selected.map(list => {
          if (string === '') {
            string = `"${list.value}"`
          } else {
            string = `${string}, "${list.value}"`
          }
          return 0
        })
      }
      return string
    },
    parseFile(file) {
      function parseComplete(results) {
        this.content = results.data
      }

      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: parseComplete.bind(this),
      })
    },
    async addStartups(bvModalEvent) {
      bvModalEvent.preventDefault()
      if (this.content.length > 0) {
        const keys = Object.keys(this.content[0])
        if (keys.includes('startup_name') && keys.includes('founder_name') && keys.includes('founder_email') && keys.includes('founder_designation') && keys.includes('startup_Industry') && keys.includes('startup_stage') && keys.includes('startup_website') && keys.includes('startup_description')) {
          this.mutationLoading = true
          let fileLink = ''
          try {
            fileLink = await this.uploadFile(this.file)
          } catch (error) {
            this.showAlert('Error uploading file!', 'Error')
          } finally {
            this.mutationLoading = false
          }
          await this.$apollo.mutate(
            {
              mutation: gql`mutation MyMutation {
                          insert_programs_knowledgeresourcetable_one(object: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}", file: "${fileLink}", is_internal: "${this.is_internal}", operations_id: ${this.selectedOperation}}) {
                            id
                          }
                        }`,
              update: () => {
                this.mutationLoading = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Resource added successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              },
            },
          ).catch(() => {
            this.mutationLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'ErrorIcon',
                variant: 'danger',
              },
            })
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Improper CSV Uploaded, Please refer the sample file.',
              icon: 'ErrorIcon',
              variant: 'danger',
            },
          })
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'There must be at least 1 startup to upload',
            icon: 'ErrorIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  apollo: {
    startups: {
      query() {
        return gql`
        query startups {
          users_associationtable(
            where: {
              role: {_in: [${this.filters[0].selected ? `${this.getList(0)}` : '"startup", "pre-incubatee", "incubatee", "graduate"'}]},
              users_organizationtable: {
                ${this.filters[1].selected ? `
                  computed_age: {
                    ${this.filters[1].selected.value !== 'custom' && this.filters[1].selected.value < 40 ? `
                      _lte: "${this.filters[1].selected.value}"
                    ` : ''}
                    ${this.filters[1].selected.value !== 'custom' && this.filters[1].selected.value === 40 ? `
                      _gt: "36"
                    ` : ''}
                    ${this.filters[1].selected.value === 'custom' ? `
                      _lte: "${this.maxValue}", _gt: "${this.minValue}"
                    ` : ''}
                  },
                ` : ''}
                ${this.filters[2].selected ? `
                  industry: {_in: [${this.getList(2)}]},
                ` : ''}
                ${this.filters[3].selected ? `
                  startup_stage: {_in: [${this.getList(3)}]},
                ` : ''}
                ${this.filters[4].selected ? `
                  offerings_type: {_in: [${this.getList(4)}]},
                ` : ''}
                ${this.filters[5].selected ? `
                  focus_type: {_in: [${this.getList(5)}]},
                ` : ''}
                ${this.filters[6].selected ? `
                  customer_type: {_in: [${this.getList(6)}]},
                ` : ''}
              },
              incubator_id: {_eq: ${this.selectedOrg.orgId}}}) {
            id
            role
            users_organizationtable {
              title
              url
            }
          }
        }`
      },
      update(data) {
        const result = []
        data.users_associationtable.forEach(startup => {
          result.push({
            id: startup.id,
            type: startup.role === 'startup' ? 'network' : startup.role,
            ...startup.users_organizationtable,
          })
        })
        return result
      },
    },
  },
}
</script>

<style>
  .dropdown-toggle::after {
    display:none;
  }
  .filters-box{
    border-top: solid 1px #d3d3d3;
    border-bottom: solid 1px #d3d3d3;
  }
  .fl-col-1{
    width:fit-content;
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
  .fl-col-2{
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-left: solid 1px #d3d3d3;
    border-right: solid 1px #d3d3d3;
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: auto;
    overflow-y: visible; */
  }
  .fl-col-2::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  .fl-col-2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .fl-col-2::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  .filter-holder{
    display: flex;
  }
  .fl-col-3{
    width:fit-content;
    padding-left: 1rem;
    display: flex;
    align-items: center;
  }
</style>
